.list_item {
  list-style-position:inside;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;    
  margin-top: 8px; 
  margin-bottom: 8px;
}

.list {
  width: 100%;
  padding: 0;
  margin: 0;
}
