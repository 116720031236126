@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: "ArtifaktLegend", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

.ag-header-group-cell-with-group {
  background-color: #374151 !important;
  color: white !important;
}

.ag-theme-quartz {
  --ag-font-size: 12px;
  --ag-header-column-separator-height: 100%;
  --ag-header-column-separator-color: white;
  --ag-header-column-resize-handle-color: white;
  
  --ag-header-column-resize-handle-height: 100%;
  --ag-header-foreground-color: white;
  --ag-header-background-color: #2dc275;
  --ag-cell-horizontal-padding: 8px;
  --ag-wrapper-border-radius: 4px;
}

.ag-header-group-cell {
  justify-content: center;
}

.ag-header-cell-label {
  justify-content: center;
  text-overflow: clip;
  overflow: visible;
  white-space: normal;
}

.ag-checkbox-input-wrapper.ag-disabled {
  cursor: not-allowed;
  background-color: #a3a4a7;
}

/**
 * Custom CSS for react-flow
 */

 .react-flow__handle-top {
  top: 0px !important;
 }

 .react-flow__handle-bottom {
  bottom: 0px !important;
 }

 .react-flow__panel.react-flow__attribution.bottom.right {
  display: none;
 }



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

strong {
  font-weight: bold !important;
}
.ql-toolbar {
  position: sticky !important;
  top: 0;
  z-index: 999;
}

.ql-container {
  /* overflow-x:auto; */
  overflow-y: scroll !important;
  height: 400px !important;
}

input[type="range"] {
  font-size: 1.5rem;
  width: 12.5em;
}

input[type="range"] {
  --thumb-height: 0.8em;
  --track-height: 0.1em;
  --track-color: black;
  --brightness-hover: 180%;
  --brightness-down: 80%;
  --clip-edges: 0.125em;
}

input[type="range"] {
  position: relative;
  background: #fff0;
  overflow: hidden;
}

input[type="range"]:active {
  cursor: grabbing;
}

input[type="range"]:disabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: not-allowed;
}
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  position: relative;
}

input[type="range"]::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
  --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
  --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
    100vmax currentColor;

  width: var(--thumb-width, var(--thumb-height));
  background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
    50% calc(var(--track-height) + 1px);
  background-color: currentColor;
  box-shadow: var(--box-fill);
  border-radius: var(--thumb-width, var(--thumb-height));

  filter: brightness(100%);
  clip-path: polygon(
    100% -1px,
    var(--clip-edges) -1px,
    0 var(--clip-top),
    -100vmax var(--clip-top),
    -100vmax var(--clip-bottom),
    0 var(--clip-bottom),
    var(--clip-edges) 100%,
    var(--clip-further) var(--clip-further)
  );
}

input[type="range"]:hover::-webkit-slider-thumb {
  filter: brightness(var(--brightness-hover));
  cursor: grab;
}

input[type="range"]:active::-webkit-slider-thumb {
  filter: brightness(var(--brightness-down));
  cursor: grabbing;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
    100% calc(var(--track-height) + 1px);
}
@media screen and (min-width: 768px) {
  .my-grid {
    display: grid;
    grid-template-columns: 287px 1fr 340px;
    column-gap: 55px;
  }
  .my-grid p {
    font-size: 14px;
    line-height: normal !important;
  }
}
.ace_content {
  height: auto !important;
  position: static !important;
}
.ace_editor {
  width: 95% !important;
  /* margin-top: 50px !important; */
}
.ql-size-huge {
  font-size: 32px;
  line-height: 36px;
}

.ql-size-large {
  font-size: 16px;
  line-height: 20px;
}

.ql-size-normal {
  font-size: 16px;
  line-height: 20px;
}

.ql-size-small {
  font-size: 13px;
  line-height: 16px;
}

.ql-bold {
  font-weight: bold;
}

.ql-italic {
  font-style: italic;
}

.ql-underline {
  text-decoration: underline;
}

.ql-strikethrough {
  text-decoration: line-through;
}

.ql-list {
  list-style-type: none;
}

.ql-bullet {
  list-style-type: disc;
}
ul {
  list-style: disc;
  padding: 0 20px;
  margin: auto;
}
ol {
  list-style-type: decimal;
  padding: 0 20px;
  margin: auto;
}
.ql-indent-1 {
  margin-left: 1em;
}
.ql-indent-2 {
  margin-left: 2em;
}
.ql-indent-3 {
  margin-left: 3em;
}
.ql-indent-4 {
  margin-left: 4em;
}
.ql-indent-5 {
  margin-left: 5em;
}
.ql-indent-6 {
  margin-left: 6em;
}
.ql-indent-7 {
  margin-left: 7em;
}
.ql-indent-8 {
  margin-left: 8em;
}
.ql-indent {
  text-indent: 1em;
}
.ql-font-serif {
  font-family: serif;
}

.ql-outdent {
  text-indent: -1em;
}

.ql-link {
  color: #0000ee;
  text-decoration: underline;
}

.ql-image {
  display: block;
  margin: 0 auto;
}

.ql-formula {
  display: block;
  margin: 0 auto;
}

.ql-code-block {
  display: block;
  margin: 0 auto;
  padding: 10px;
  background-color: #ffffff;
}

.ql-syntax {
  font-family: monospace;
}

.ql-quote {
  border-left: 4px solid #cccccc;
  padding-left: 10px;
}

.ql-header {
  font-size: 20px;
  line-height: 24px;
}

.ql-subscript {
  font-size: 75%;
  line-height: 1em;
  vertical-align: sub;
}

.ql-superscript {
  font-size: 75%;
  line-height: 1em;
  vertical-align: super;
}
