.tile {
  @apply block no-underline;
  box-sizing: initial;
  width: 300px;
  min-height: 314px;
  padding: 28px;
  background-color: #f5f5f5;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 5px 5px rgb(220, 220, 220);
  }

  p {
    margin: 8px 0 0 0;
    color: #949494;
    text-decoration: none;
    font-size: 15px !important;
  }

  h4 {
    margin-bottom: 0;
    color: black;
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
  }

  &.coming_soon {
    h4 {
      color: #9f9f9f;
    }
    &:hover {
      box-shadow: unset;
      transform: unset;
      cursor: initial;
    }
  }
}

.image_container {
  width: 300px;
  height: 160px;
  background-color: #dee8e6;
  position: relative;

  img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
